<div class="page-content overflow-x-hidden">
  <section class="hero--bg hero" id="hero" style="margin-bottom: -5rem">
    <header id="header" class="container">
      <lib-ikp-header-logo></lib-ikp-header-logo>
    </header>
    @if (isLoaded) {
      <header class="hero-header container pb-8">
        <div class="row">
          <div class="col-lg-7 align-self-center">
            @if (showAuthServerAlert) {
              <mwe-alert
                [type]="authServerAlertData.type"
                [titleKey]="authServerAlertData.title"
                [messageKey]="authServerAlertData.message"
              ></mwe-alert>
            }
            <h1 class="mb-5 mb-lg-6">
              <div class="h6 mb-3 text-white">
                <span>{{ 'global.home.titleSmall' | translate }}</span>
              </div>
              <div class="h1 mb-0 text-white">
                <span>{{ 'global.home.titleBig' | translate }}</span>
              </div>
            </h1>
            <div class="mb-5 mb-lg-6">
              <mwe-button
                [showHoverEffect]="true"
                (clicked)="login()"
                class="d-block mb-3"
                buttonId="mwe-home-login"
                color="white"
                labelKey="global.home.login"
              ></mwe-button>
              @if (environmentService.isRegistrationEnabled) {
                <mwe-button
                  (clicked)="register()"
                  buttonId="mwe-home-register"
                  color="white"
                  category="secondary"
                  labelKey="global.home.register"
                  class="d-block mb-3"
                ></mwe-button>
              }
            </div>
          </div>
          <div class="col-lg-5 position-relative d-lg-block d-none" aria-hidden="true">
            <div class="mx-auto ratio ratio-smartphone" style="max-width: 236px; filter: drop-shadow(0 22px 45px rgba(0, 0, 0, 0.33))">
              <div class="smartphone-display">
                <img
                  class="img-fluid w-100 rounded"
                  ngSrc="assets/img/ikp-frontpage-smartphone-bg.png"
                  alt=""
                  width="210"
                  height="335"
                  priority
                />
              </div>
            </div>
          </div>
        </div>
      </header>
    }
  </section>
</div>
